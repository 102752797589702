import React from "react";
import { Container, Text, Icon, Button } from "@atoms";
import clsx from "clsx";
import { useAppState } from "@state";
import camelCase from "lodash.camelcase";

const colors = {
  0: "bg-slate text-white",
  1: "bg-yellow text-slate",
};

const Checkmark = () => {
  return (
    <span className="flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-purple text-white">
      <Icon name="checkmark" className="h-3 w-3" />
    </span>
  );
};

const ModalContent = ({ title, copy, button }) => {
  const [, dispatch] = useAppState();

  const closeModal = () => {
    dispatch({ type: "closeModal" });
  };

  return (
    <div className="min-h-10 w-full max-w-lg rounded-xl bg-white p-6 py-9 text-center">
      <button
        type="button"
        className="absolute right-0 top-0 z-30 flex items-center p-3 text-xs font-bold uppercase text-purple transition duration-200 hover:opacity-70"
        onClick={closeModal}
      >
        <Icon name="close" className="h-7 w-7" />
        <span className="sr-only">Close</span>
      </button>

      <Text variant="h6" className="mb-4">
        {title}
      </Text>
      {copy && (
        <Text
          variant="sm"
          className="my-4 prose-ol:text-left prose-ul:text-left"
          richText
        >
          {copy}
        </Text>
      )}
      {button?.url && (
        <Button to={button?.url} color="purple" size="sm" onClick={closeModal}>
          {button?.text}
        </Button>
      )}
    </div>
  );
};

const TableHeading = ({
  colHeading,
  colDescription,
  i,
  length,
  rounded,
  className,
}) => {
  return (
    <div
      className={clsx(
        "sticky -top-3 z-20 p-3",
        colors[i % length],
        rounded && "rounded-t-xl",
        className
      )}
    >
      <Text className="font-bold" variant="body">
        {colHeading}
      </Text>
      <Text variant="xs">{colDescription}</Text>
    </div>
  );
};

const OffersingTable = ({ offerings, columnHeadings }) => {
  const [, dispatch] = useAppState();

  const openModal = modal => {
    const { title } = modal;

    dispatch({
      type: "openModal",
      title,
      content: React.memo(() => <ModalContent {...modal} />),
      background: "bg-black/80",
      hideButton: true,
    });
  };

  const headings = Object.entries(
    columnHeadings.reduce((all, current) => {
      return { ...all, [camelCase(current.colHeading)]: current };
    }, {})
  );

  return (
    <Container className="my-10 sm:my-20">
      <div className="-top-3 z-20 hidden grid-cols-2 sm:sticky sm:grid sm:grid-cols-3">
        <div className="hidden items-center rounded-t-xl bg-purple p-3 font-bold text-white sm:flex">
          Offerings
        </div>
        {columnHeadings?.map(({ colHeading, colDescription }, i) => (
          <TableHeading
            colHeading={colHeading}
            colDescription={colDescription}
            i={i}
            length={columnHeadings?.length}
            rounded
          />
        ))}
      </div>

      {/* desktop table */}
      {offerings?.map((offering, i) => {
        const { title, label, copy, button } = offering;

        return (
          <div className="hidden grid-cols-2 divide-x divide-purple/15 border-x-2 border-b-2 border-purple/15 sm:grid sm:grid-cols-3">
            <span className="flex flex-col border-b border-white sm:col-span-1">
              <button
                type="button"
                className="h-full bg-purple/15 p-2 text-left hover:bg-purple/25"
                onClick={() => openModal({ title, copy, button })}
              >
                <span className="flex items-center justify-between gap-2 text-purple">
                  <Text variant="sm">{title}</Text>

                  <Icon name="info" className="h-3 w-3 shrink-0" />
                </span>
                {label && <Text variant="xs">{label}</Text>}
              </button>
            </span>

            {headings?.map(([key, value]) => {
              const hasKey = offering[key];
              return (
                <span
                  className={clsx("flex items-center justify-center p-3", {
                    "hidden sm:flex": !hasKey,
                  })}
                >
                  {hasKey && <Checkmark />}
                </span>
              );
            })}
          </div>
        );
      })}

      {/* mobiel table */}
      {headings?.map(([key, value], i) => {
        return (
          <>
            <TableHeading
              {...value}
              i={i}
              rounded={i === 0}
              length={columnHeadings?.length}
              className="sm:hidden"
            />
            {offerings
              ?.filter(offering => offering[key])
              ?.map(({ title, label, copy, button }) => (
                <div className="grid grid-cols-2 divide-x divide-purple/15 border-x-2 border-b-2 border-purple/15 sm:hidden sm:grid-cols-3">
                  <span className="flex flex-col border-b border-white sm:col-span-1">
                    <button
                      type="button"
                      className="h-full bg-purple/15 p-2 text-left hover:bg-purple/25"
                      onClick={() => openModal({ title, copy, button })}
                    >
                      <span className="flex items-center justify-between gap-2 text-purple">
                        <Text variant="sm">{title}</Text>

                        <Icon name="info" className="h-3 w-3 shrink-0" />
                      </span>
                      {label && <Text variant="xs">{label}</Text>}
                    </button>
                  </span>

                  <span className="flex items-center justify-center p-3">
                    <Checkmark />
                  </span>
                </div>
              ))}
          </>
        );
      })}
    </Container>
  );
};

export default OffersingTable;
